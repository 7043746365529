//Table scrollbar style
.simplebar-custom .simplebar-track {
  background-color: white !important;
}

.simplebar-form-style {
  height: 78vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-table-list {
  height: 68vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-three-column-layout {
  height: 75.1vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-view-page-tab {
  height: 69vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-three-column-layout-entry-item {
  height: 75.3vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-view-page-style-for-tab {
  height: 67vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-form-in-crm {
  height: 88vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-list-page-with-information-tile {
  height: 63.8vh !important;
  // overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-for-list-page-with-single-filter-line {
  height: 68vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-for-list-page-with-double-filter-line {
  height: 59.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-for-forms {
  height: 77.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-view-page-tab-height-for-rct {
  height: 64vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-for-list-page-with-single-filter-line-button-below {
  height: 63.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-for-inter-branch-transactions {
  height: 64.5vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.simplebar-style-for-inter-branch-transactions-without-filter {
  height: 73vh !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.with_pagination_rental_contract_report {
  height: 73vh !important;
}

.without_pagination_rental_contract_report {
  height: 67vh !important;
}

.customer-form-simplebar-style {
  height: 78vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}