.table-data-info {
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .card {
        flex: 1 1 calc(12.5% - 10px);
        min-width: 100px;
        height: 100%;
        border-radius: 30px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        .name {
            font-size: 11px !important;
        }

        .value {
            font-size: 18px !important;
            font-weight: 800;
        }

    }
}