.custom-accordion-drilldown > .custom-accordion-item-drilldown {
  .custom-accordion-header-drilldown {
    .accordion-button {
      padding: 10px 20px 10px 20px !important;
    }
    .accordion-button:focus {
      box-shadow: 0 0 0 0.1rem #2684ff !important;
    }
  }
}

.custom-accordion-drilldown-child > .custom-accordion-item-drilldown-child {
  .custom-accordion-header-drilldown-child {
    .accordion-button {
      padding: 4px 12px 4px 12px !important;
    }
    .accordion-button:focus {
      box-shadow: 0 0 0 0.1rem #2684ff !important;
    }
  }
  .custom-accordion-item-drilldown-child-table {
    max-height: fit-content !important;
  }
}

.timeline-accordion {
  position: relative;
  padding-left: 25px;

  &::before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #969696;
  }

  .timeline-item {
    position: relative;
    padding-left: 10px;

    .timeline-marker {
      position: absolute;
      left: -19px;
      top: 18.5px;
      width: 9px;
      height: 9px;
      background-color: #007bff;
      z-index: 1;
      transition: transform 0.3s ease;

      .active & {
        transform: scale(1.2);
      }
    }

    .timeline-marker-group {
      background-color: #f35800;
    }

    .timeline-marker-ledger {
      background-color: #1cd582;
    }
  }
}
