.zatca-view-modal {
    height: 100vh;

    .modal-header {
        img {
            width: 40px;
            height: 40px;
            object-fit: contain;
        }

        h5 {
            font-size: 15px;
        }

        .zakath-logo {
            right: 100px;
            width: 150px;
            height: 63px
        }
    }

    .modal-body {
        .qr-code-body {
            // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            height: 300px;
            width: 300px;

            .qr-code-scanner {
                width: 100%;
                height: 100%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

            }

        }

        .button-group {
            button {
                font-size: 12px;
                outline: none;
                border: none;
                border-radius: 5px !important;
                background-color: rgb(28 140 58 / 90%);
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }


        }

        .download-button {
            font-size: 12px;
            background-color: #3873b5;
            outline: none;
            border: none;
        }

        .response-section {
            .heading-layout {
                background-color: #282828;
                color: white;
                font-size: 11px;
            }

            .response-layout {
                background-color: #192833;
                color: white;
                width: 100%;
                padding: 20px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                height: 300px;
                max-height: 300px;
                max-height: 300px !important;
                overflow: scroll;

            }

            ::-webkit-scrollbar {
                display: block !important;
                width: 10px;
            }

            ::-webkit-scrollbar-thumb {
                background: #bababa !important;
                border-radius: 8px !important;
                margin-left: 20px !important;
                cursor: pointer;
            }

            ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #535353 !important;
                border-radius: 10px !important;

            }
        }




    }
}